import * as React from "react"
import Button from "../components/button"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero-motion"
import BlockTitle from "../components/blockTitle"
import BlockImage from "../components/blockImage"
import BlockParagraph from "../components/blockParagraph"
import BlockSection from "../components/blockSection"
import BlockContentGrid from "../components/blockContentGrid"
import BlockCallout from "../components/blockCallout"
import BlockVideo from "../components/blockVideo"
import FeatureRow from "../components/featureRow"
import Testimonials from "../components/testimonials"
import TestimonialItem from "../components/testimonialItem"

// Icons
import { faTruckRampBox } from "@fortawesome/free-solid-svg-icons"
import { faBell } from "@fortawesome/free-solid-svg-icons"
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons"
import { faBullhorn } from "@fortawesome/free-solid-svg-icons"
import { faFileAlt } from "@fortawesome/free-solid-svg-icons"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"

// Images
import freshlyImage from "../images/freshly.png"
import videoPosterImage from "../images/freshlyinventory_video_poster@2x.jpg"
import logoPacariChocolate from "../images/logos/PacariChocolate-logo.png"
import logoEightOunce from "../images/logos/EightOunce-logo.png"
import logoTheRiceFactory from "../images/logos/TheRiceFactory-logo.png"
import logoBaliBody from "../images/logos/BaliBody-logo.png"
import logoZadies from "../images/logos/logo-zadies.png"
import logoGS1 from "../images/logos/partner-logo-gs1.svg"

const ProductPage = () => {
  const pageTitle = "Freshly Inventory"
  const pageDescription =
    "Track products by batch or lot expiry dates to manage perishable inventory, prepare for audits & recalls, reduce food waste, and boost profits."

  return (
    <Layout pageTitle={pageTitle}>
      <Seo title={pageTitle} description={pageDescription} />

      <Hero
        heroText="Traceability and batch tracking for small businesses"
        heroDesc="Effortless traceability of inventory batch or lot numbers, and expiry dates to ensure customer satisfaction and effective product recalls."
        heroButtonTextPrimary="Get Started for Free"
        heroButtonLinkPrimary="https://apps.shopify.com/freshly?utm_source=freshly-commerce&utm_medium=landing-page"
        heroButtonTextSecondary="Sign In"
        heroButtonLinkSecondary="https://freshly.glassbox.app/shopify/login"
      />

      <BlockVideo
        blockVideoPosterImageSrc={videoPosterImage}
        blockVideoPosterImageAlt="Video"
        blockVideoURL="https://www.youtube.com/embed/ejonurj3RC4"
      />

      <BlockSection id="about" sectionTitle="Features">
        <FeatureRow
          featureBlockIcon1={faTruckRampBox}
          featureBlockTitle1="Batch Tracking"
          featureBlockDescription1="Create batches for each product variant and easily trace customer orders back to the batches they belonged."
          featureBlockIcon2={faBell}
          featureBlockTitle2="Expiry Tracking & Alerts"
          featureBlockDescription2="Clearly see which product batches are approaching their expiry dates and get notified when they expire."
        />
        <FeatureRow
          featureBlockIcon1={faBoxOpen}
          featureBlockTitle1="FEFO/FIFO Fulfillment"
          featureBlockDescription1="Auto-assign or manual-assign batches to fulfill orders using the First-expire, First-out or First-in, First-out method."
          featureBlockIcon2={faBullhorn}
          featureBlockTitle2="Recall Preparedness"
          featureBlockDescription2="Quickly collect batch, customer, and order information in the event of a recall so that you can serve your customers with confidence."
        />
        <FeatureRow
          featureBlockIcon1={faFileAlt}
          featureBlockTitle1="Customizable Packing Slips"
          featureBlockDescription1="Add batch details to your packing slips, so you can select the right batches for every order."
          featureBlockIcon2={faCalendarAlt}
          featureBlockTitle2="Customer-Facing Expiry Dates"
          featureBlockDescription2="Display expiry dates to your customers on your store's product pages."
        />
      </BlockSection>

      <BlockImage
        blockImageSrc={freshlyImage}
        blockImageAlt="Freshly Inventory"
        blockImageCaption="Freshly Inventory"
      />

      <BlockSection id="partner" sectionTitle="Our Partner">
        <div className="grid g-m-1-13 g-l-4-13 g-4-11 margin-top-xl">
          <div className="g-m-1-13 g-t-1-4 t-subdued">
            <a href="https://www.gs1us.org/" target="_blank" rel="noreferrer">
              <img src={logoGS1} alt="GS1 USA" />
            </a>
          </div>
          <div className="g-m-1-13 g-t-5-13 g-l-5-11 g-5-13">
            <p className="t-18">
              We’ve partnered with{" "}
              <a href="https://www.gs1us.org/" target="_blank" rel="noreferrer">
                GS1 US
              </a>{" "}
              to focus on product safety, traceability, and e-commerce
              innovation. Recognized as the most widely-used supply chain
              benchmarks globally, GS1 Standards complement Freshly's commitment
              to help brands and retailers manage and trace perishable products
              online.
            </p>
          </div>
        </div>
      </BlockSection>

      <BlockSection id="ourcustomers" sectionTitle="Our Customers">
        <BlockTitle>Trusted by thousands of independent stores</BlockTitle>

        <div className="block-logos g-m-1-13 g-l-4-11 g-4-11">
          {/* <img src={logoBalibody} alt="BaliBody" />
          <img src={logoDivyas} alt="Divyas Kitchen" />
          <img src={logoKnowseafood} alt="Know Seafood" />
          <img src={logoRiceFactory} alt="The Rice Factory" /> */}
          <img src={logoPacariChocolate} alt="Pacari Chocolate" />
          <img src={logoEightOunce} alt="Eight Ounce" />
          <img src={logoTheRiceFactory} alt="The Rice Factory" />
          <img src={logoBaliBody} alt="Bali Body" />
        </div>

        <Testimonials link="https://apps.shopify.com/freshly#reviews?utm_source=freshly-commerce&utm_medium=landing-page">
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly has been a godsend. The batch reporting is excellent should we ever have a recall. Best of all, it informs us before our batches expire so we can put them on sale. What an amazing app that is only outdone by their customer service."
              author="Premium eJuice"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly is intuitive with smart, automated features. Definitely a must-have if you're selling products with batches and expiry dates. Inventory batch bulk updates are quick & easy with CSV uploads."
              author="Mum's Medicine Cabinet"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-1-6 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly helps us manage inventory expiration dates and speed up the sales of slow-moving stock. The packing slips allows our staff pack orders using first-in, first-out. The support is top notch!"
              author="Food Craft"
            />
          </div>
          <div className="block-content g-m-1-13 g-l-7-12 margin-bottom-medium">
            <TestimonialItem
              desc="Freshly has made batch tracking easy and efficient for our medical devices. The app showcases expiration dates, offers promotions on expiring batches, and removes inventory that's close to expiry. Highly recommended!"
              author="Lumiquick Diagnostics Store"
            />
          </div>
        </Testimonials>
      </BlockSection>

      <BlockSection id="faq" sectionTitle="Frequently Asked Questions">
        <BlockContentGrid>
          <div className="block-content g-m-1-13 g-l-1-6">
            <div className="block-content-header">
              <h3 className="t-18">How does Freshly affect my store?</h3>
            </div>
            <p className="t-16">
              We reliably sync your Shopify products and inventory activity.
              Product inventory changes as you add batches with item quantities
              on Freshly. All inventory updates are tracked on Freshly.
            </p>
          </div>

          <div className="block-content g-m-1-13 g-l-7-12">
            <div className="block-content-header">
              <h3 className="t-18">
                Do I have to track inventory on Shopify and Freshly?
              </h3>
            </div>
            <p className="t-16">
              Only the products that require batch tracking should be managed on
              Freshly.
            </p>
          </div>

          <div className="block-content g-m-1-13 g-l-1-6">
            <div className="block-content-header">
              <h3 className="t-18">
                Does Freshly replace my Shopify inventory and fulfillment
                manager?
              </h3>
            </div>
            <p className="t-16">
              No, we extend and enhance the capabilities of your Shopify's
              inventory and fulfillment manager to suit your requirements for
              batching and/or expiry dates. Freshly is built to work seamlessly
              with your existing workflow.
            </p>
          </div>

          <div className="block-content g-m-1-13 g-l-7-12">
            <div className="block-content-header">
              <h3 className="t-18">
                Does Freshly impact my existing checkout apps?
              </h3>
            </div>
            <p className="t-16">
              No, Freshly tracks inventory and assigns batches automatically
              using webhooks on the backend and does not use line item custom
              attributes.
            </p>
          </div>

          <div className="block-content g-m-1-13 g-l-7-10">
            <p className="t-18">
              <a
                href="https://help.getfreshly.io/?utm_source=freshly-commerce&utm_medium=landing-page"
                target="_blank"
                rel="noreferrer"
              >
                Visit Our Help Center
              </a>{" "}
              ⟶
            </p>
          </div>
        </BlockContentGrid>
      </BlockSection>
      <BlockCallout id="Awards">
        <BlockParagraph
          blockTitle="Awards"
          blockContent={
            <>
              <a
                href="https://www.fda.gov/food/new-era-smarter-food-safety/meet-winners-fdas-low-or-no-cost-food-traceability-challenge"
                target="_blank"
                rel="noreferrer"
              >
                FDA's New Era of Smarter Food Safety Traceability Award
              </a>
              <br />
              <br />
              <a
                href="https://www.shopify.ca/partners/blog/shopify-app-challenge-winners"
                target="_blank"
                rel="noreferrer"
              >
                Shopify's App Challenge Award
              </a>{" "}
            </>
          }
        />
      </BlockCallout>
    </Layout>
  )
}

export default ProductPage
